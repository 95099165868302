import styled from 'styled-components';
import { TextBlock } from '@carvana/showroom-content';
import { typeStyle } from '@carvana/showroom-css-theme';
import {
  flexAlignVerticalContent,
  mq,
  MOBILE,
  LARGE_MOBILE,
} from '../../styles/mixins';
import SplashVideo from './SplashVideo';

const HeroContainer = styled.div`
  ${flexAlignVerticalContent('center', 'center')};
  width: 100%;
  height: 400px;

  ${mq(LARGE_MOBILE, `
    height: 350px;
  `)}
`;

const HeroContent = styled(TextBlock)`
  max-width: 650px;

  ${mq(LARGE_MOBILE, `
    max-width: 450px;
  `)}

  ${mq(MOBILE, `
    width: 100%;
    padding: 0 24px;
  `)}
`;

const HeroTitle = styled(TextBlock.BrandTitle)`
  // This is a bit of a hack. We need to be more specific than the styles we're overriding
  &.landing.title {
    ${typeStyle('brand-m')}
    color: var(--cvna-color-white);
    line-height: 1.1;
    
    ${mq(LARGE_MOBILE, `
      font-size: 28px;
    `)}
  }
`;

const HeroDescription = styled(TextBlock.Description)`
  // This is a bit of a hack. We need to be more specific than the styles we're overriding
  &.landing.description {
    ${typeStyle('header-s')}
    color: var(--cvna-color-white);
    font-weight: 450;

    ${mq(LARGE_MOBILE, `
      font-size: 16px;
    `)}
  
    ${mq(MOBILE, `
      width: 100%;
      max-width: 250px;
      margin: 0 auto;
  `)}
  }
`;

const Hero = () => (
  <SplashVideo>
    <HeroContainer>
      <HeroContent align='center' size='responsive'>
        <HeroContent.Halo />
        <HeroTitle className='landing title'>
          Carvana's Online Auction For Dealers
        </HeroTitle>
        <HeroDescription className='landing description'>
          Do you buy wholesale vehicles for a used car dealership?
          <br />
          Get direct access to Carvana's most recent trade-ins.
        </HeroDescription>
      </HeroContent>
    </HeroContainer>
  </SplashVideo>
);

export default Hero;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { typeStyle } from '@carvana/showroom-css-theme';
import { flexAlignHorizontalContent, flexAlignVerticalContent, LARGE_MOBILE, mq } from '../../styles/mixins';
import Image from '../Image';

const renderBodyText = (body) => {
  const bodyText = body.split('**');
  return bodyText.map((text, index) => {
    return index % 2 === 0 ? text : <strong key={ text }>{text}</strong>;
  });
};

/**
 * ValueProp component
 * @param {string} title - The title of the value prop.
 * @param {string} body - The body text of the value prop. Supports bolding using markdown syntax (ex: `**bold text**`).
 * @param {string} imageUrl - The url of the image.
 * @param {boolean} reverse - Whether or not to reverse the order of the image and text.
 */
const ValueProp = ({ title, body, imageUrl, reverse }) => {
  return (
    <ValuePropContainer $reverse={ reverse }>
      <ValuePropTextContent>
        <ValuePropTitle>
          {title}
        </ValuePropTitle>
        <ValuePropBody>
          {renderBodyText(body)}
        </ValuePropBody>
      </ValuePropTextContent>
      <ValuePropImageContainer>
        <Image
          src={ imageUrl }
          alt={ title }
          width={ 564 }
          height={ 416 }
          layout='responsive'
          sizes={
            `(max-width: 768px) 100vw,
            (max-width: 1200px) 50vw,
            33vw`
          }
        />
      </ValuePropImageContainer>
    </ValuePropContainer>
  );
};

const ValuePropContainer = styled.div`
  ${flexAlignHorizontalContent('space-between', 'center')};
  ${({ $reverse }) => $reverse && 'flex-direction: row-reverse;'}
  gap: 96px;
  padding: 16px 40px;

  ${mq(LARGE_MOBILE, `
    ${flexAlignVerticalContent('center', 'center')};
    gap: 32px;
  `)}
`;

const ValuePropTextContent = styled.div`
  ${flexAlignVerticalContent('normal', 'center')};
  width: 50%;
  gap: 8px;

  ${mq(LARGE_MOBILE, `
    ${flexAlignVerticalContent('center', 'center')};
    text-align: center;
    width: 100%;
  `)}
`;

const ValuePropTitle = styled.p`
  ${typeStyle('header-m')};
  color: var(--cvna-color-blue-6);
`;

const ValuePropBody = styled.p`
  ${typeStyle('body-l')};
  color: var(--cvna-color-grey-5);
`;

const ValuePropImageContainer = styled.div`
  position: relative;
  width: 50%;

  ${mq(LARGE_MOBILE, `
    width: 100%;
  `)}
`;

ValueProp.defaultProps = {
  title: '',
  body: '',
  imageUrl: '',
  reverse: false
};

ValueProp.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  imageUrl: PropTypes.string,
  reverse: PropTypes.bool
};

export default ValueProp;

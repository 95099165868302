import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { saleStartEasternTime } from '@src/utils/time';
import SplitCTA from '@components/SplitCTA';
import useCid from '@hooks/useCid';
import useSegmentPage from '@hooks/useSegmentPage';
import Button from '@components/Button';
import Hero from '@components/LandingPage/Hero';
import ValueProp from '@components/LandingPage/ValueProp';
import Testimonials from '@components/LandingPage/Testimonials';
import MaxContentWrapper from '@components/MaxContentWrapper';
import CircularCountdown from '@components/CountdownTimer/CircularCountdown';
import {
  ValuePropsSection,
  TimerSection,
  TimerContent,
  TimerTitle,
  TimerDescription,
  TimerLabel,
  CTASection,
  CTATitle,
  TestimonialsSection
} from '@components/LandingPage/styles';

import '@carvana/showroom-toast/index.css';
import '@carvana/showroom-modal/index.css';
import '@carvana/showroom-content/index.css';

export default function LandingPage() {
  const [saleStart, setSaleStart] = useState(saleStartEasternTime());
  const {
    auctionValueProps = [],
    auctionTestimonials = []
  } = useFlags();
  const signInUrl = useCid(process.env.CARVANA_ACCESS_LOGIN_URL, 'APP-C4PWEB-FRONTPAGE');

  useEffect(() => {
    setSaleStart(saleStart.toLocal());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useSegmentPage('Front Page');

  return (
    <>
      <Hero size='small' />
      <CTASection>
        <CTATitle>Shop our Partner Marketplaces</CTATitle>
        <SplitCTA page='marketing' />
      </CTASection>
      {auctionValueProps?.length ? (
        <ValuePropsSection>
          <MaxContentWrapper width={ 1024 }>
            {auctionValueProps.map(({ title, body, imageUrl }, idx) => (
              <ValueProp
                key={ title }
                title={ title }
                body={ body }
                imageUrl={ imageUrl }
                reverse={ idx % 2 !== 0 }
              />
            ))}
          </MaxContentWrapper>
        </ValuePropsSection>
      ) : null}
      <TimerSection>
        <MaxContentWrapper width={ 1024 }>
          <TimerContent>
            <TimerContent.Halo />
            <TimerTitle>
              <FormattedMessage id='TODAYS_SALE' />
            </TimerTitle>
            <TimerDescription>
              <FormattedMessage id='DAILY_SALES_EVERY_WEEKDAY' values={{ time: saleStart.toFormat('ha') }} />
            </TimerDescription>
            <CircularCountdown />
            <TimerLabel size='small'>
              <FormattedMessage id='UNTIL_SALE_ENDS' />
            </TimerLabel>
            <Button
              href={ signInUrl.href }
              target='_blank'
              segmentProps={{
                event: 'Manheim Sign In Clicked',
                props: {
                  component: 'Today\'s Sale Timer'
                }
              }}
            >
              <FormattedMessage id='MANHEIM_SIGN_IN' />
            </Button>
          </TimerContent>
        </MaxContentWrapper>
      </TimerSection>
      {auctionTestimonials?.length ? (
        <TestimonialsSection>
          <MaxContentWrapper>
            <Testimonials testimonials={ auctionTestimonials } />
          </MaxContentWrapper>
        </TestimonialsSection>
      ) : null}
    </>
  );
}

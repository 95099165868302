import PropTypes from 'prop-types';
import styled from 'styled-components';
import { typeStyle } from '@carvana/showroom-css-theme';
import Carousel from '@components/Carousel';
import { flexAlignHorizontalContent, flexAlignVerticalContent, LARGE_MOBILE, mq } from '@src/styles/mixins';

const Testimonials = ({ testimonials }) => {
  if (!testimonials?.length) return null;

  return (
    <TestimonialsContainer>
      <Carousel
        wrapAround
        renderBottomCenterControls={ props => <CarouselPaginationDots { ...props } /> }
      >
        {testimonials.map(({ review, author, company }) => (
          <TestimonialCard key={ author }>
            <TestimonialTitle>
              Hear from Our Dealer Partners
            </TestimonialTitle>
            <TestimonialContent>
              <Quote />
              <div>
                <ReviewText>{review}</ReviewText>
                <ReviewAuthor>{author}</ReviewAuthor>
                <ReviewBusiness>{company}</ReviewBusiness>
              </div>
            </TestimonialContent>
          </TestimonialCard>
        ))}
      </Carousel>
    </TestimonialsContainer>
  );
};

const TestimonialsContainer = styled.div`
  padding: 0 24px;
  ${mq(LARGE_MOBILE, `
    padding: 0;
    .carousel--pagination-wrap {
      display: none;
    }
  `)}
`;

const TestimonialTitle = styled.h2`
  ${typeStyle('header-m')}
  color: var(--cvna-color-blue-6);
`;

const TestimonialCard = styled.div`
  ${flexAlignVerticalContent('normal', 'normal')}
  height: 100%;
  margin: 0 112px;
  background: var(--cvna-color-grey-1);
  padding: 56px 64px;
  gap: 16px;

  ${mq(LARGE_MOBILE, `
    padding: 32px;
    margin: 0 24px;
  `)}
`;

const TestimonialContent = styled.div`
  ${flexAlignHorizontalContent('normal', 'normal')}
  gap: 8px;

  ${mq(LARGE_MOBILE, `
    ${flexAlignVerticalContent('normal', 'normal')}
    gap: 0;
  `)}
`;

const Quote = styled.span`
  ${typeStyle('brand-xl')}
  height: 43px;
  font-weight: 700;
  color: var(--cvna-color-blue-2);
  ::before {
    content: '“';
  }
`;

const ReviewAuthor = styled.span`
  ${typeStyle('header-xs')}
  color: var(--cvna-color-blue-2);
`;

const ReviewBusiness = styled.span`
  ${typeStyle('header-xs')}
  color: var(--cvna-color-grey-5);
  ::before {
    content: ' ';
  }
`;

const ReviewText = styled.p`
  ${typeStyle('body-l')}
  font-weight: 390;
  color: var(--cvna-color-grey-5);
  margin-bottom: 16px;
`;

const CarouselPaginationDots = styled(Carousel.PaginationDots)`
  position: absolute;
`;

Testimonials.defaultProps = {
  testimonials: []
};

Testimonials.propTypes = {
  testimonials: PropTypes.arrayOf(PropTypes.shape({
    review: PropTypes.string,
    author: PropTypes.string,
    company: PropTypes.string,
  }))
};

export default Testimonials;

import styled from 'styled-components';
import { TextBlock } from '@carvana/showroom-content';
import { typeStyle } from '@carvana/showroom-css-theme';
import {
  flexAlignVerticalContent,
  mq,
  MOBILE,
  LARGE_MOBILE,
  DESKTOP
} from '../../styles/mixins';

export const Title = styled.h1`
  line-height: 1.1;
  font-weight: bold;
  color: ${({ theme }) => theme.color.primary.v600};
  text-transform: uppercase;
  ${mq(MOBILE, `
    font-size: 28px;
  `)};
  ${mq(DESKTOP, 'font-size: 42px;')};
`;

export const ValuePropsSection = styled.section`
  width: 100%;
  padding-top: 32px;
  padding-bottom: 42px;
  background-color: ${({ theme }) => theme.color.neutral.v100};

  ${mq(LARGE_MOBILE, `
    padding-top: 40px;
    padding-bottom: 60px;
  `)}
`;

export const TimerSection = styled.section`
  width: 100%;
  text-align: center;
  padding: 40px 0;
`;

export const TimerContent = styled(TextBlock)`
  ${flexAlignVerticalContent('center', 'center')};
`;

export const TimerTitle = styled.h1`
  ${typeStyle('brand-s')};
  color: var(--cvna-color-blue-6);
  margin-bottom: 8px;
  text-transform: uppercase;
  
  ${mq(LARGE_MOBILE, `
    font-size: 28px;
  `)}
`;

export const TimerDescription = styled(TextBlock.Description)`
  max-width: 350px;
  margin: 0 auto 24px auto;
`;

export const TimerLabel = styled.h2`
  ${typeStyle('header-s')};
  text-transform: uppercase;
  color: var(--cvna-color-blue-6);
  margin-bottom: 24px;
`;

export const CTASection = styled.section`
  width: 100%;
  padding: 24px;
  background: var(--cvna-color-white);
  text-align: center;
`;

export const CTATitle = styled.h2`
  ${typeStyle('header-xl')};
  color: var(--cvna-color-blue-6);
  margin-bottom: 24px;
`;

export const TestimonialsSection = styled.section`
  padding: 108px 0;

  ${mq(LARGE_MOBILE, `
    padding: 60px 0;
  `)}
`;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useOnResize from '../../hooks/useOnResize';
import { mq, LARGE_MOBILE } from '../../styles/mixins';

const VIDEO_WIDTH = 1920;
const VIDEO_HEIGHT = 1080;
const MAX_HEIGHT_FULL = 700;
const MAX_HEIGHT_MOBILE = 476;
const MOBILE_WIDTH_MAX = 840;

const getVideoMax = (screen) => {
  let maxHeight = MAX_HEIGHT_FULL;
  if (screen.width <= MOBILE_WIDTH_MAX) {
    maxHeight = MAX_HEIGHT_MOBILE;
  }
  return maxHeight;
};

const calculateAspect = (screen) => {
  const maxHeight = getVideoMax(screen);
  const currentHeight = screen.width * (VIDEO_HEIGHT / VIDEO_WIDTH);
  const diffHeight = currentHeight - maxHeight;

  let videoStyles = {
    top: `-${(diffHeight / 2)}px`,
    width: '100%',
    height: `${currentHeight}px`,
  };

  if (currentHeight < maxHeight) {
    const currentWidth = maxHeight * (VIDEO_WIDTH / VIDEO_HEIGHT);
    const diffWidth = currentWidth - screen.width;

    videoStyles = {
      marginLeft: `-${(diffWidth / 2)}px`,
      width: `${currentWidth}px`,
      height: maxHeight
    };
  }

  return videoStyles;
};

export default function SplashVideo({ children }) {
  const screen = useOnResize();
  const videoStyles = calculateAspect(screen);

  return (
    <VideoContent>
      <VideoContainer>
        <Video
          style={ videoStyles }
          muted
          loop
          autoPlay
          playsInline
          preload='metadata'
        >
          <source
            src={ `${process.env.CVNA_APP_CDN_URL}/images/carvana-access-video.webm` }
            type='video/webm'
          />
          <source
            src={ `${process.env.CVNA_APP_CDN_URL}/images/carvana-access-video.mp4` }
            type='video/mp4'
          />
        </Video>
      </VideoContainer>
      { children }
    </VideoContent>
  );
}

SplashVideo.defaultProps = {
  children: null
};

SplashVideo.propTypes = {
  children: PropTypes.node
};

const VideoContainer = styled.div`
  overflow: hidden;
  width: 100%;
  z-index: -1;
`;

const VideoContent = styled.div`
  display: grid;
  max-width: 100%;
  max-height: 400px;

  ${mq(LARGE_MOBILE, `
    height: 350px;
  `)}

  > * {
    grid-row: 1;
    grid-column: 1;
  }
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: fill;
`;
